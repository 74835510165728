import './App.css';
import ComingSoon from './ComingSoonPage/ComingSoon';
import PageTransition from './ComingSoonPage/Components/PageTransition';

function App() {
  return (
    <div className="App">
      <PageTransition>
        <ComingSoon />
      </PageTransition>
    </div>
  );
}

export default App;
