// PageTransition.js
import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './PageTransition.css'; // Ensure this file exists and is correctly named

const PageTransition = ({ children }) => (
  <TransitionGroup>
    <CSSTransition
      appear
      timeout={1000}
      classNames="fade"
    >
      <div>{children}</div>
    </CSSTransition>
  </TransitionGroup>
);

export default PageTransition;
