import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Form.css';

const Form = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 600);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const validateName = (name) => {
        const re = /^[a-zA-Z\s]*$/;
        return re.test(name);
    };

    const validatePhoneNumber = (phoneNumber) => {
        const re = /^[0-9]*$/;
        return re.test(phoneNumber);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');

        if (!name || !email || !phoneNumber) {
            setError('Name, email, and phone number are required.');
            return;
        }

        if (!validateEmail(email)) {
            setError('Please enter a valid email address.');
            return;
        }

        if (!validateName(name)) {
            setError('Name cannot contain numbers.');
            return;
        }

        if (!validatePhoneNumber(phoneNumber)) {
            setError('Phone number can only contain numbers.');
            return;
        }

        if (isSubmitting) return;
        setIsSubmitting(true);

        try {
            const response = await axios.post('https://poweredbuybackend-fda5fdcqepd3ajc3.eastus-01.azurewebsites.net/api/notify', { name, email, company, phoneNumber });

            if (response.status === 201) {
                setSuccess('Successful!');
                setName('');
                setEmail('');
                setCompany('');
                setPhoneNumber('');
            }
        } catch (err) {
            if (err.response && err.response.status === 409) {
                setError('Email address already exists.');
            } else {
                setError('Failed to add notification. Please try again.');
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <div className="notification-form">
                <input
                    type="text"
                    placeholder={isSmallScreen ? "Name" : "Enter your name"}
                    className="notification-input"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <input
                    type="email"
                    placeholder={isSmallScreen ? "Email address" : "Email address"}
                    className="notification-input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    type="text"
                    placeholder={isSmallScreen ? "Company Name" : "Company Name"}
                    className="notification-input"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                />
                <input
                    type="text"
                    placeholder={isSmallScreen ? "Phone number" : "Phone number"}
                    className="notification-input"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <button
                    className="notification-button"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                >
                    Notify Me
                </button>
            </div>

            {isSubmitting && <div className="loader"></div>} {/* Loader component */}

            <div className="message-container">
                {error && <p className="error-message">{error}</p>}
                {success && <p className="success-message">{success}</p>}
            </div>
        </>
    );
};

export default Form;
