import React from 'react';
import Form from './Form';
import './Title.css';

const Title = () => {
  return (
    <div className="body">
      <div className="container">
        <div className="header">
          <span className="headerText">PoweredBuy</span>
          <span className="badge">COMING SOON</span>
        </div>
        <h1 className="heading">
          <span className="highlight">Spend Analysis </span> automated intelligently 
        </h1>
        <p className="paragraph">
          Leverage AI driven spend analytics to rapidly identify and action cost savings across your organisation.
        </p>
        <Form />
        <p className="paragraph2">
          Stay updated with the latest features and launch announcements!
        </p>
      </div>
    </div>
  );
};

export default Title;
