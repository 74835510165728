import React from 'react'
import Title from './Components/Title'


function ComingSoon() {
  return (
    <div >
      <Title/>
    </div>
  )
}

export default ComingSoon
